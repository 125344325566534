<template>
  <v-sheet color="grey lighten-5">
    <v-container fluid>
      <v-row align="start" justify="start" class="pa-6">
        <v-col cols="8">
          <v-list color="transparent">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>
                  mdi-email
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.email }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>
                  mdi-phone
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.phone_number }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>
                  mdi-calendar-range
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="item.on_job_position">
                    {{ formatDate(item.on_job_position.started_at) }}</span
                  >
                  <span v-else> {{ formatDate(item.created_at) }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>
          </v-list>
        </v-col>
        <v-col class="font-italic pa-6">{{ item.note }} </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import permission from "@/mixins/permission";
import date from "@/mixins/date";
export default {
  Name: "generalTab",

  mixins: [permission, date],
  data() {
    return {};
  },
  props: {
    item: {
      required: true,
      type: [Object, Array]
    }
  },
  computed: {},
  methods: {}
};
</script>
